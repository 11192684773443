import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { useRouteMatch, Switch, Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import OnboardingStart from '../Onboarding/OnboardingStart';

const OnboardingRouting = ({ userId }) => {
  const { url } = useRouteMatch();
  console.log('🚀 ~ OnboardingRouting ~ url:', url);
  const history = useHistory();

  const { onbordingStatus } = useSelector((state) => state.clientReducer);

  useEffect(() => {
    if (onbordingStatus === null) {
      history.push(`${url}/start`);
    }
  }, [onbordingStatus]);

  return (
    <Switch>
      <Route path={`${url}/start`}>
          <OnboardingStart
            userId={userId}
          />
        </Route>
    </Switch>
  );
};

export default OnboardingRouting;

OnboardingRouting.propTypes = {
  userId: PropTypes.string,
};
