import React from 'react';
import MainFooter from '../../components/MainFooter';
import Container from '../../components/Container';

import './style.scss';

const OnboardingStart = () => {
  return (
    <div className="profile-wrap" style={{ position: 'relative' }}>
    <div style={{ padding: '35px 24px', height: '100%' }}>
      <Container widthP={100} height={'100%'}>
      <div className='onbording-card-wrapper'>
        <div className='onbording-card-left'>

        </div>
        <div className='onbording-card-right'>

        </div>
      </div>

      </Container>
    </div>
    <MainFooter />
    </div>
  );
};

export default OnboardingStart;
