import { axios } from '../utils/API';
import { API_URLS } from '../constants/api_urls';

export class ClientService {
  static createClients = async (data) => {
    return await axios.post(API_URLS.CREATE_CLIENT, data);
  };

  static getAllClients = async () => {
    return await axios.get(API_URLS.ALL_CLIENTS, {
      params: { limit: 1000, offset: 0 },
    });
  };

  static getClient = async (data) => {
    const { id, portfolio = false } = data;
    return await axios.get(
      `${API_URLS.CLIENT}/${id}${portfolio ? '' : '?include_portfolio=false'}`
    );
  };

  static setClientType = async (data) => {
    const { id, type } = data;
    return await axios.post(`${API_URLS.SET_CLIENT_TYPE}/${id}/type`, {
      ...type,
      client_id: +id,
    });
  };

  static updateClientInfo = async (data) => {
    const { id, client } = data;
    return await axios.put(`${API_URLS.UPDATE_CLIENT}/${id}`, client);
  };

  static addClientPhone = async (data) => {
    const { id, phone } = data;
    return await axios.post(`${API_URLS.CLIENT}/${id}/phone`, { phone });
  };

  static updateClientPhone = async (data) => {
    const { id, ID, phone } = data;
    return await axios.post(`${API_URLS.CLIENT}/${id}/phone`, {
      ID,
      phone,
    });
  };

  static deleteClientPhone = async (data) => {
    const { id, phoneID } = data;
    return await axios.delete(`${API_URLS.CLIENT}/${id}/phone/${phoneID}`);
  };

  static getRepresentative = async (clientID) => {
    return await axios.get(`/v1/clients/${clientID}/company/representive`);
  };

  static addRepresentative = async (data) => {
    const { id, representative } = data;
    return await axios.post(
      `/v1/clients/${id}/company/representive`,
      representative
    );
  };

  static updateRepresentative = async (data) => {
    const { clientID, id, representative } = data;
    return await axios.put(
      `/v1/clients/${clientID}/company/representive/${id}`,
      representative
    );
  };

  static deleteRepresentative = async (data) => {
    const { id, ID } = data;
    return await axios.delete(
      `${API_URLS.CLIENT}/${id}/company/representive/${ID}`
    );
  };

  static addRepresentativeDoc = async (data) => {
    const { id, representativeId, file, type } = data;
    const sendData = new FormData(file);
    sendData.append('type', type);
    return await axios.post(
      `/v1/clients/${id}/company/representive/${representativeId}/documents`,
      sendData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
  };

  static setClientMaritalStatus = async (data) => {
    const { id, marital } = data;
    return await axios.put(
      `${API_URLS.CLIENT}/${id}/marital-status-and-employment`,
      marital
    );
  };

  static addClientScore = async (data) => {
    const { id, score } = data;
    return await axios.put(`${API_URLS.CLIENT}/${id}/score`, score);
  };

  static setClientAddress = async (data) => {
    const { id, address } = data;
    return await axios.put(`${API_URLS.CLIENT}/${id}/address`, address);
  };

  static setClientData = async (data) => {
    const { id, fields } = data;
    return await axios.post(`${API_URLS.CLIENT}/${id}/data`, fields);
  };

  static getClientData = async (data) => {
    const { id, name } = data;
    return await axios.get(`${API_URLS.CLIENT}/${id}/data/${name}`);
  };

  static sendInvitation = async (data) => {
    const { id, email } = data;
    return await axios.post(`${API_URLS.CLIENT}/${id}/invite`, { email });
  };

  static getInvitationInfo = async (data) => {
    const { inviteHash } = data;
    return await axios.get(`${API_URLS.INVITE}/${inviteHash}`);
  };

  static acceptInvitation = async (data) => {
    const { inviteHash, password } = data;
    return await axios.post(`${API_URLS.INVITE}/${inviteHash}`, { password });
  };

  static setRiskLevel = async (data) => {
    const { id, riskSelected } = data;
    return await axios.post(`${API_URLS.CLIENT}/${id}/risk`, {
      risk_level: +riskSelected,
    });
  };

  static sendClientApprove = async (data) => {
    const { id, status } = data;
    return await axios.post(`${API_URLS.CLIENT}/${id}/approve`, { status });
  };

  static setClientFees = async (data) => {
    const { id, fees } = data;
    return await axios.post(`${API_URLS.CLIENT}/${id}/fees`, fees);
  };

  static getClientFees = async (id) => {
    return await axios.get(`${API_URLS.CLIENT}/${id}/fees`);
  };

  static deleteClient = async (id) => {
    return await axios.delete(`${API_URLS.CLIENT}/${id}`);
  };

  static getFinPlan = async (id) => {
    return await axios.get(`${API_URLS.CLIENT}/${id}/financialplan`);
  };

  static createFinPlan = async ({ clientId, data }) => {
    return await axios.post(
      `${API_URLS.CLIENT}/${clientId}/financialplan`,
      data
    );
  };

  static patchFinPlan = async ({ clientId, finplanId, body }) => {
    return await axios.patch(
      `${API_URLS.CLIENT}/${clientId}/financialplan/${finplanId}`,
      body
    );
  };

  static deleteFinPlan = async ({ clientId, finplanId }) => {
    return await axios.delete(
      `${API_URLS.CLIENT}/${clientId}/financialplan/${finplanId}`
    );
  };

  static uploadImage = async (file, finplanId, itemId) => {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    return await axios.post(
      `${API_URLS.UPLOAD_IMAGE}/${finplanId}/expenses/${itemId}`,
      bodyFormData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );
  };

  static getSuggestionsPortfolio = async (userId) => {
    return await axios.get(
      `${API_URLS.CLIENT}/${userId}/suggestions?offset=0&limit=100`
    );
  };

  static getApprovedPortfolio = async (userId) => {
    return await axios.get(
      `${API_URLS.CLIENT}/${userId}/approved?offset=0&limit=100`
    );
  };

  static getExecutedPortfolio = async (userId) => {
    return await axios.get(
      `${API_URLS.CLIENT}/${userId}/executing?offset=0&limit=100`
    );
  };

  static getImplementedPortfolio = async (userId) => {
    return await axios.get(
      `${API_URLS.CLIENT}/${userId}/implemented?offset=0&limit=100`
    );
  };

  static getAllApprovedPortfolio = async () => {
    const offset = 0;
    const limit = 100;
    return await axios.get(`${API_URLS.ALL_APPROVED}/approved`, {
      params: { limit, offset },
    });
  };

  static getAllExecutedPortfolio = async () => {
    const offset = 0;
    const limit = 100;
    return await axios.get(`${API_URLS.ALL_APPROVED}/executing`, {
      params: { limit, offset },
    });
  };

  static getAllImplementedPortfolio = async () => {
    const offset = 0;
    const limit = 100;
    return await axios.get(`${API_URLS.ALL_APPROVED}/implemented`, {
      params: { limit, offset },
    });
  };

  static approveSuggestions = async ({ userId, candidatId }) => {
    return await axios.post(
      `${API_URLS.CLIENT}/${userId}/candidate/${candidatId}/approve`
    );
  };

  static deleteSuggestions = async ({ userId, candidatId }) => {
    return await axios.delete(
      `${API_URLS.CLIENT}/${userId}/candidate/${candidatId}`
    );
  };
  /* eslint-disable camelcase */

  static getCandidatTransactions = async ({ portfolio_id, candidatId }) => {
    return await axios.get(
      `${API_URLS.PORTFOLIO}/${portfolio_id}/candidate/${candidatId}/transactions`
    );
  };

  // TODO
  static executeApprovedPortfolio = async ({ portfolioId, candidatId }) => {
    return await axios.post(
      `${API_URLS.PORTFOLIO}/${portfolioId}/candidate/${candidatId}/execute`
    );
  };
  // portfolio/:id/candidate/:candidateId/transactionspdf

  static downloadPdfReport = async ({ portfolioId, candidatId }) => {
    return await axios.get(
      `${API_URLS.PORTFOLIO}/${portfolioId}/candidate/${candidatId}/transactionspdf`,
      {
        responseType: 'blob',
      }
    );
  };
}
